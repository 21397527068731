import { useEffect, useRef, useState } from 'react';
import { Field as FinalField, Form as FinalForm } from 'react-final-form';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import applicantRegistrationSuccessExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/registration/applicant_registration_success';
import { ButtonScale } from 'bloko/blocks/button';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import VSpacing from 'bloko/blocks/vSpacing';

import sendFbPixelRegistrationConversion from 'HH/SendFbPixelRegistrationConversion';
import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import AccountDeleteInactive from 'src/components/AccountDeleteInactive';
import AccountDeleteInactiveSelect, { DELETE_INACTIVE_TIME_DEFAULT } from 'src/components/AccountDeleteInactive/Select';
import AdvertisementAgreementCheckbox from 'src/components/AdvertisementAgreement/Checkbox';
import AdvertisementSubscriptionApplicant from 'src/components/AdvertisementSubscription/AdvertisementSubscriptionApplicant';
import Control from 'src/components/ApplicantSignInSignUpFlow/Control';
import { getFieldProps, loginBy } from 'src/components/ApplicantSignInSignUpFlow/SignInStep/SignInForm';
import Submit from 'src/components/ApplicantSignInSignUpFlow/Submit';
import VKAppListener from 'src/components/ApplicantSignInSignUpFlow/VKAppListener';
import Form from 'src/components/Form';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useAddEventPixelsRu } from 'src/hooks/useAddEventPixelsRu';
import useApplicantSignupFields from 'src/hooks/useApplicantSignupFields';
import useAutofocus from 'src/hooks/useAutofocus';
import { useDV360Analytics } from 'src/hooks/useDV360Analytics';
import useFingerprints from 'src/hooks/useFingerprints';
import useSuccessCreateUser, { createUser } from 'src/hooks/useOTPSignupFlow';
import usePostponedVacancy from 'src/hooks/usePostponedVacancy';
import { useSelector } from 'src/hooks/useSelector';
import useVkAppIntegrationFocus from 'src/hooks/useVkAppIntegrationFocus';
import { SIGNUP_FORM } from 'src/hooks/useVkAppIntegrationReady';
import formatAnalyticErrors from 'src/utils/analytics/formatAnalyticErrors';
import createVrDraftResume from 'src/utils/vr/createVrDraftResume';

const ADVERTISMENT_AGREEMENT_ARTICLE_ID = '32356';

const mapErrors = (errors) =>
    errors.reduce((result, { key, description, field, error }) => {
        result[key || field] = {
            error: description || error,
        };
        return result;
    }, {});

const SignUpForm = ({ trls, render, code, password, isSignupPage, isSignupMain, backurl }) => {
    const { login, firstName, lastName, authType } = useSelector(({ otp }) => otp);
    const { addNotification } = useNotification();
    const fields = useApplicantSignupFields();
    const [fieldsSignup, setFieldsSignup] = useState({});
    const formElement = useRef();
    const [isFetching, setFetching] = useState(false);
    const successCreateUser = useSuccessCreateUser(backurl);
    const currentVacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const { postponedVacancy, postponedVacancyInFavorites } = usePostponedVacancy();
    const postponedVacancyId = postponedVacancy?.vacancyId;
    const vacancyId = currentVacancyId || (postponedVacancyInFavorites ? null : postponedVacancyId);
    const focusHandle = useVkAppIntegrationFocus(SIGNUP_FORM);
    const autofocusRef = useAutofocus();
    const vrResponseFormSubmitted = useRef(false);
    const { vrResponseHash, profRolesFromSurvey, vrFingerPrints } = useSelector(({ vrSignupLogin }) => vrSignupLogin);
    const fingerprints = useFingerprints('applicant');
    const sendDV360Analytics = useDV360Analytics();
    const afterSignupBackurl = useSelector(({ router }) => {
        if (backurl) {
            return backurl;
        }
        const queryBackurl = router.location.query.backurl;
        return queryBackurl && decodeURIComponent(queryBackurl);
    });
    const postponed = useSelector(({ router }) => router.location.search.includes('postponed'));
    const analyticsVacancyId = vacancyId ?? postponedVacancyId;
    const addEventPixels = useAddEventPixelsRu();

    useEffect(() => {
        Analytics.sendHHEvent('element_shown', {
            elementName: `enter_full_name`,
            vacancyId: analyticsVacancyId,
            authType: loginBy[authType] || 'unknown',
            isSignupPage,
        });
    }, [analyticsVacancyId, login, isSignupPage, authType]);

    const hideErrors = () => {
        setFieldsSignup(
            mapErrors([
                { key: 'firstName', error: null },
                { key: 'lastName', error: null },
            ])
        );
    };

    const sendAnalytics = (error) => {
        Analytics.sendHHEvent('form_submit', {
            formName: 'enter_full_name',
            vacancyId: analyticsVacancyId,
            authType: loginBy[authType] || 'unknown',
            errors: formatAnalyticErrors('full_name', error?.response?.data?.errors, ['key', 'description']),
            isSignupPage,
        });
        applicantRegistrationSuccessExternal({ label: 'site' });
    };

    const submitForm = async (values) => {
        setFetching(true);
        const props = {
            ...values,
            ...(vrResponseHash ? vrFingerPrints : fingerprints),
            firstName: values.firstName || '',
            lastName: values.lastName || '',
            isSignupPage,
            isSignupMain,
            vacancyId,
            postponed,
            backurl: afterSignupBackurl,
        };

        let response;

        try {
            response = await createUser(props);
        } catch (error) {
            setFetching(false);
            if (Array.isArray(error?.response?.data?.errors)) {
                setFieldsSignup(mapErrors(error.response.data.errors));
                sendAnalytics(error);
                return;
            }

            defaultRequestErrorHandler(error, addNotification);
            return;
        }

        sendAnalytics(null);
        sendDV360Analytics('APPLICANT_REGISTERED');
        addEventPixels('B2C_APPLICANT_REGISTRATION');
        // tempexp_25028_nextline
        sendFbPixelRegistrationConversion(false);

        hideErrors();
        setFetching(false);

        if (vrResponseHash) {
            await createVrDraftResume({
                vrResponseHash,
                profRolesFromSurvey,
                onFail: (error) => defaultRequestErrorHandler(error, addNotification),
            });
            return;
        }

        successCreateUser(response);
    };
    const vrFormSubmit = useRef(() =>
        submitForm({
            vrResponseHash,
            login,
            code,
        })
    );

    useEffect(() => {
        if (vrResponseHash && !vrResponseFormSubmitted.current) {
            vrResponseFormSubmitted.current = true;
            vrFormSubmit.current();
        }
    }, [vrResponseHash]);

    if (vrResponseHash) {
        return <Loading scale={LoadingScale.Small} />;
    }

    const renderFirstNameField = ({ size } = {}) => {
        return (
            <FinalField
                component={Control}
                onFocus={focusHandle}
                placeholder={trls[SignUpForm.trls.firstName]}
                data-qa="account-signup-firstname"
                capitalize
                size={size}
                inputRef={autofocusRef}
                {...getFieldProps(fields.firstName, fieldsSignup.firstName)}
            />
        );
    };

    const renderLastNameField = ({ size } = {}) => {
        return (
            <FinalField
                component={Control}
                onFocus={focusHandle}
                placeholder={trls[SignUpForm.trls.lastName]}
                data-qa="account-signup-lastname"
                capitalize
                size={size}
                {...getFieldProps(fields.lastName, fieldsSignup.lastName)}
            />
        );
    };

    const renderAccountDeleteInactive = () => {
        return (
            <AccountDeleteInactive
                renderSelect={() => (
                    <FinalField
                        name="deleteInactiveTime"
                        type="select"
                        initialValue={DELETE_INACTIVE_TIME_DEFAULT}
                        render={({ input }) => {
                            return <AccountDeleteInactiveSelect {...input} />;
                        }}
                    />
                )}
                spacingAfter={<VSpacing base={4} />}
            />
        );
    };

    const renderSubmit = ({ isLarge } = {}) => {
        return (
            <Submit
                text={trls[SignUpForm.trls.submit]}
                isFetching={isFetching}
                dataQa="account-signup-submit-applicant-info"
                scale={isLarge ? ButtonScale.Large : undefined}
                size={isLarge ? 'large' : 'medium'}
            />
        );
    };

    const renderAdvertisementAgreement = () => {
        return (
            <FinalField
                name="agreementAdv"
                type="checkbox"
                initialValue={false}
                render={({ input }) => {
                    return <AdvertisementAgreementCheckbox {...input} articleId={ADVERTISMENT_AGREEMENT_ARTICLE_ID} />;
                }}
            />
        );
    };

    const renderAdvertisementSubscriptionApplicant = () => {
        return (
            <FinalField
                name="agreementAdv"
                type="checkbox"
                initialValue={false}
                render={AdvertisementSubscriptionApplicant}
            />
        );
    };

    return (
        <FinalForm
            onSubmit={submitForm}
            initialValues={{
                firstName,
                lastName,
                login,
                code,
                password,
            }}
            render={({ handleSubmit, form }) => (
                <VKAppListener form={form}>
                    <Form
                        action="/account/signup"
                        method="POST"
                        data-qa="account-signup"
                        onSubmit={(event) => {
                            event.preventDefault();
                            handleSubmit(event);
                        }}
                        ref={formElement}
                    >
                        {render({
                            renderFirstNameField,
                            renderLastNameField,
                            renderAccountDeleteInactive,
                            renderSubmit,
                            renderAdvertisementAgreement,
                            renderAdvertisementSubscriptionApplicant,
                        })}
                    </Form>
                </VKAppListener>
            )}
        />
    );
};

SignUpForm.trls = {
    header: 'otp.confirm.header.signup',
    firstName: 'newApplicant.firstName',
    lastName: 'newApplicant.lastName',
    submit: 'signupForm.create.submit',
};

SignUpForm.propTypes = {
    trls: PropTypes.object,
    render: PropTypes.func,
    code: PropTypes.string,
    password: PropTypes.string,
    backurl: PropTypes.string,
    isSignupPage: PropTypes.bool,
    isSignupMain: PropTypes.bool,
    errorPageLogin: PropTypes.bool,
};

export default translation(SignUpForm);
