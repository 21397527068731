import { useState, useCallback, useEffect, ReactNode } from 'react';
import { Form as FinalForm, Field as FinalField } from 'react-final-form';

import Analytics from '@hh.ru/analytics-js';
import { PasswordInput, Button as ButtonMagritte } from '@hh.ru/magritte-ui';
import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import { FormError } from 'bloko/blocks/form';
import InputPassword from 'bloko/blocks/inputText/inputPassword';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

const CHECK_PASSWORD_URL = '/multiaccount_grouping/check_employer_password';

declare global {
    interface FetcherPostApi {
        [CHECK_PASSWORD_URL]: {
            queryParams: void;
            body: {
                password: string;
            };
            response: void;
        };
    }
}

interface RenderSubmitProps {
    isStretched?: boolean;
}

interface MultiAccountGroupingFormProps {
    next: (password: string) => void;
    render: (args: {
        renderInput: () => ReactNode;
        renderError: () => ReactNode;
        renderSubmit: (args?: RenderSubmitProps) => ReactNode;
    }) => ReactNode;
}

export type MultiAccountGroupingFormBaseProps = Omit<MultiAccountGroupingFormProps, 'render'>;

const TrlKeys = {
    passwordFromLetter: 'login.passwordFromLetter',
    continue: 'multiaccount.grouping.continue',
    wrongPassword: 'multiaccount.grouping.checkPassword.wrong',
};

const MultiAccountGroupingForm: TranslatedComponent<MultiAccountGroupingFormProps> = ({ trls, render, next }) => {
    const [isSending, setIsSending] = useState(false);
    const [wrongPassword, setWrongPassword] = useState(false);
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);

    useEffect(() => {
        Analytics.sendHHEvent('screen_shown', {
            hhtmSource: 'multiaccount_enter_password',
        });
    }, []);

    const onSubmit = useCallback(
        async (values: { password: string }) => {
            setIsSending(true);
            setWrongPassword(false);
            Analytics.sendHHEventButtonClick('continue');

            try {
                await fetcher.postFormData(CHECK_PASSWORD_URL, {
                    ...values,
                });
            } catch (_) {
                setWrongPassword(true);
                return;
            } finally {
                setIsSending(false);
            }

            next(values.password);
        },
        [next]
    );

    const renderInput = () => {
        if (isAnonymousMagritteExp) {
            return (
                <FinalField
                    name="password"
                    render={({ input, meta }) => (
                        <PasswordInput
                            {...input}
                            type={undefined}
                            data-qa="password-input"
                            invalid={meta.invalid && meta.submitFailed}
                            autoFocus
                            autoCorrect="off"
                            autoCapitalize="off"
                            placeholder={trls[TrlKeys.passwordFromLetter]}
                        />
                    )}
                />
            );
        }
        return (
            <FinalField
                name="password"
                render={({ input, meta }) => (
                    <InputPassword
                        {...input}
                        data-qa="password-input"
                        invalid={meta.invalid && meta.submitFailed}
                        autoFocus
                        autoCorrect="off"
                        autoCapitalize="off"
                        placeholder={trls[TrlKeys.passwordFromLetter]}
                        onToggle={(isPasswordVisible) => {
                            Analytics.sendHHEventButtonClick('login_password_view_toggle', {
                                isPasswordVisible,
                            });
                        }}
                    />
                )}
            />
        );
    };

    const renderError = () => {
        return (
            <FormError show={wrongPassword} data-qa="password-error">
                {trls[TrlKeys.wrongPassword]}
            </FormError>
        );
    };

    const renderSubmit = ({ isStretched }: RenderSubmitProps = {}) => {
        if (isAnonymousMagritteExp) {
            return (
                <ButtonMagritte
                    mode="primary"
                    style="accent"
                    disabled={isSending}
                    type="submit"
                    data-qa={'password-submit'}
                >
                    {trls[TrlKeys.continue]}
                </ButtonMagritte>
            );
        }
        return (
            <Button
                kind={ButtonKind.Primary}
                type={ButtonType.Submit}
                disabled={isSending}
                data-qa={'password-submit'}
                stretched={isStretched}
            >
                {trls[TrlKeys.continue]}
            </Button>
        );
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
                <Form method="post" onSubmit={handleSubmit}>
                    {render({ renderInput, renderError, renderSubmit })}
                </Form>
            )}
        />
    );
};

export default translation(MultiAccountGroupingForm);
